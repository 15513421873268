<template>
  <v-row>
        <v-col cols="12">
       <v-card flat outlined :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
         <v-card-text>
           <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="3">
              <DropDownList dense :title="'ประเภท'" :rules="formTypeRules" :placeholder="'ประเภท'" :required="true" :items="formTypeItems" item-text="formTypeName" return-object v-model="formType"/>
            </v-col>
             <v-col cols="12" md="3">
              <DropDownList dense :title="'ประจำปี'" :rules="yearRules" :placeholder="'โปรดเลือก'" :required="true" :items="yearItems" v-model="year" item-text="yearTH" item-value="yearEN"/>
            </v-col>
             <v-col cols="12" md="3">
              <TextField dense number v-model="target" :title="'เป้าหมาย'" :placeholder="'เป้าหมาย'"/>
            </v-col>
             <v-col cols="12" md="3">
                <label class="text-title-4 natural--text text--darken-4">ดำเนินการ
              <v-btn color="primary"  block @click="postSiteTarget()" :loading="loading">เพิ่มเป้าหมาย</v-btn>
               </label>
            </v-col>
          </v-row>
           </v-form>
         </v-card-text>
       </v-card>
        </v-col>
         <v-col cols="12">
       <v-card flat outlined :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
         <v-card-text>
        <v-data-table :loading="loading" :items="targetItems" :headers="headers">
          <template slot="no-data">
              <div class="text-center text-title-3 natural--text text--lighten-1 pt-2">
              ไม่มีข้อมูลเป้าหมาย
            </div>
          </template>
             <template v-slot:item.action="{ item }">
              <v-row align="center" v-if="!item.editable">
                <v-btn icon @click="item.editable = true">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-row>
              <template v-else>
                 <v-row align="center">
                    <v-spacer/>
              <v-btn color="primary" width="70"  @click="patchSiteTarget(item)">บันทึก</v-btn>
              <v-spacer/>
              <v-btn outlined color="primary" width="70"  @click="item.editable = false">ยกเลิก</v-btn>
               <v-spacer/>
                 </v-row>
              </template>
            </template>
             <template v-slot:item.target="{ item }">
               <template v-if="item.editable">
                 <div class="pt-4">
               <TextField dense v-model="item.target"/>
                 </div>
               </template>
               <template v-else>
                {{item.target}}
               </template>
             </template>
               <template v-slot:item.delete="{ item }">
                 <v-btn icon @click="onDelete(item)"><v-icon>mdi-delete</v-icon></v-btn>
               </template>
        </v-data-table>
         </v-card-text>
       </v-card>
         </v-col>
          <v-dialog v-model="confirmDeleteDialog" width="384" persistent>
           <ConfirmDeleteCard :title="'คุณต้องลบรายการนี้หรือไม่?'" :subtitle="''" :action="'ลบข้อมูลนี้'" @onClose="confirmDeleteDialog=false" @onConfirm="deleteSiteTarget()" />
        </v-dialog>
      </v-row>
</template>

<script>
import TextField from '@/components/common/TextField'
import DropDownList from '@/components/common/DropDownList'
import { getSiteTarget, postSiteTarget, patchSiteTarget, deleteSiteTarget, getYear, getSiteFormType } from '@/api/'
import ConfirmDeleteCard from '@/components/common/ConfirmDeleteCard'

export default {
  components: {
    TextField,
    DropDownList,
    ConfirmDeleteCard
  },
  data () {
    return {
      loading: false,
      targetItems: [],
      yearItems: [],
      year: null,
      target: 0,
      formType: null,
      formTypeItems: [],
      headers: [
        { text: 'ประเภท', value: 'formTypeName', class: 'primary lighten-5' },
        { text: 'ประจำปี', value: 'year', class: 'primary lighten-5', width: '100px' },
        { text: 'เป้าหมาย', value: 'target', class: 'primary lighten-5', width: '150px' },
        { text: 'แก้ไข', value: 'action', class: 'primary lighten-5', width: '200px' },
        { text: 'ลบ', value: 'delete', class: 'primary lighten-5', width: '100px' }
      ],
      valid: false,
      formTypeRules: [v => !!v || 'กรุณาเลือกประเภทแบบฟอร์ม'],
      yearRules: [v => !!v || 'กรุณาเลือกปี'],
      confirmDeleteDialog: false,
      currentItem: null
    }
  },
  methods: {
    onDelete (item) {
      this.currentItem = item
      this.confirmDeleteDialog = true
    },
    deleteSiteTarget () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      deleteSiteTarget({ id: this.currentItem.id, etag: this.currentItem._etag, tenantId: tenant.tenantId, siteId: this.$route.params.siteId }, message => {
        if (message.data.code === 1) {
          this.fetchData()
          this.confirmDeleteDialog = false
        }
      }, error => {
        console.log(error)
      })
    },
    patchSiteTarget (item) {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      patchSiteTarget({ id: item.id, etag: item._etag, tenantId: tenant.tenantId, siteId: this.$route.params.siteId }, item, message => {
        if (message.data.code === 1) {
          this.fetchData()
          item.editable = false
        }
      }, error => {
        console.log(error)
      })
    },
    postSiteTarget () {
      if (this.$refs.form.validate()) {
        this.loading = true
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        postSiteTarget({
          tenantId: tenant.tenantId,
          siteId: this.$route.params.siteId,
          formTypeId: this.formType.formTypeId,
          formTypeName: this.formType.formTypeName,
          year: this.year,
          target: this.target
        }, message => {
          if (message.data.code === 1) {
            this.getSiteTarget()
            this.$refs.form.reset()
          }
          this.loading = false
        }, error => {
          console.log(error)
          this.loading = false
        })
      }
    },
    getSiteTarget () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      this.loading = true
      getSiteTarget({
        tenantId: tenant.tenantId,
        siteId: this.$route.params.siteId,
        offset: 0,
        limit: 100
      }, message => {
        if (message.data.code === 1) {
          // console.log(message.data.result)
          message.data.result.items.forEach(function (element) { element.editable = false })
          this.targetItems = message.data.result.items
        } else {
          this.targetItems = []
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
      })
    },
    async fetchData () {
      try {
        let resp = await getYear()
        if (resp.data.code === 1) {
          this.yearItems = resp.data.result
          this.year = [String(new Date().getFullYear())]
        }
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        resp = await getSiteFormType({
          tenantId: tenant.tenantId,
          siteId: this.$route.params.siteId,
          offset: 0,
          limit: 100,
          sortBy: 'formTypeName',
          sorDesc: 0
        })
        if (resp.data.code === 1) {
          this.formTypeItems = resp.data.result.items
          this.formType = []// this.buildArrayItem('formTypeId', resp.data.result.items)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
      this.getSiteTarget()
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style>

</style>
